<template>
  <div class="referrals">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input
          id="search"
          type="text"
          name=""
          placeholder="Nome, Cognome, Azienda, Campagna, Utente CVing"
          @input="filterSearch($event)"
        >
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="checkbox">
              <input
                type="checkbox"
                @click="toggleRowsSelection($event.target.checked)"
              >
            </th>
            <th
              v-for="header of headers"
              :key="header"
              class="orderable"
              @click="changeOrder(header)"
            >
              <div class="">
                <span v-html="header" />
                <font-awesome-icon
                  v-if="filters.order.field == header"
                  :icon="
                    filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'
                  "
                />
              </div>
            </th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr v-for="share in getFilteredRows()" :key="share.id">
            <td class="checkbox">
              <input
                :checked="share.selected"
                type="checkbox"
                @click="selectRow(document.id)"
              >
            </td>
            <td>
              {{ share.companyName }}
            </td>
            <td>
              {{ share.campaign }}
            </td>
            <td>
              {{ share.date }}
            </td>
            <td>
              {{ share.candidate }}
            </td>
            <td>
              {{ share.emailFrom }}
            </td>
            <td>
              {{ share.emailTo }}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <floating-button :menu="false" :tooltip="'Scarica i report'" @action="getCSV()" />
  </div>
</template>

<script>
import { reactive, toRefs } from 'vue'
export default {
  setup () {
    const state = reactive({
      headers: [
        'Nome azienda',
        'Nome campagna',
        'Data condivisione',
        'Nome candidato',
        'Email condividente',
        'Email ricevente'

      ],
      filters: {
        order: {
          field: 'Data caricamento',
          asc: false
        },
        search: []
      },
      shares: [
        {
          companyName: 'Pippo',
          campaign: 'Campagna',
          date: '12-02-2022',
          candidate: 'Elena Ganci',
          emailFrom: 'pippo@gmail.com',
          emailTo: 'pippo@gmail.com',
          id: '123'
        },
        {
          companyName: 'Minnie',
          campaign: 'Campagna 2',
          date: '12-02-2022',
          candidate: 'Elena Ganci',
          emailFrom: 'pippo@gmail.com',
          emailTo: 'pippo@gmail.com',
          id: '124'
        },
        {
          companyName: 'Paperino',
          campaign: 'Campagna',
          date: '12-02-2022',
          candidate: 'Elena Ganci',
          emailFrom: 'pippo@gmail.com',
          emailTo: 'pippo@gmail.com',
          id: '125'
        }
      ]
    })
    const filterSearch = ({ target }) => {
      state.filters.search = target.value ? target.value.split(' ') : []
    }
    const toggleRowsSelection = (checked) => {
      state.shares.forEach((d) => {
        d.selected = checked
      })
    }
    const changeOrder = () =>{

    }
    const getFilteredRows = () =>{
      return state.shares
    }
    const getCSV = () =>{

    }
    return {
      ...toRefs(state),
      filterSearch,
      toggleRowsSelection,
      changeOrder,
      getFilteredRows,
      getCSV
    }
  }
}
</script>

<style lang="scss" scoped>
@use "../assets/scss/referrals";
@use "../assets/scss/table";
</style>